iframe {
  width: 100%;
  height: 100%;
  border: none;
}

#hubspot-conversations-inline-parent {
  width: 100%;
  height: 80vh;
}
